import React from "react"
import Layout from "../../components/layout"
import CapabilitiesHeroSection from "../../components/capabilitiesherosection"
import AuditButtons from "../../components/auditbuttons"
import SingleButton from "../../components/singlebutton"
import { Helmet } from "react-helmet"
import Audits from "../../components/audits"
import Arrow from "../../components/arrow"

import capabilityicon from "../../images/capability-digital-strategy-2.png"

import marketanylsis from "../../images/media-attribution-modelling.png"
import gotomarketstrategy from "../../images/go-to-market-strategy.png"
import digitalaudits from "../../images/digital-audits.png"
import digitalactivityroadmaps from "../../images/digital-activity-roadmaps.png"
import mediastrategy from "../../images/social-ads.png"
import contentandsocialstrategy from "../../images/content-and-social-strategy.png"
import uxcxstrategy from "../../images/ux-cx-strategy.png"
import crmandloyaltystrategy from "../../images/crm-and-loyalty-strategy.png"

import rowtwoheroleft from "../../images/digital-strategy-row-two-hero-left.jpg"
import rowtwoheroright from "../../images/digital-strategy-row-two-hero-right.jpg"

import rowfivehero from "../../images/digital-strategy-row-five-hero.png"

import rowsixhero1 from "../../images/digital-strategy-row-six-hero-1.png"
import rowsixhero2 from "../../images/digital-strategy-row-six-hero-2.png"
import rowsixhero3 from "../../images/digital-strategy-row-six-hero-3.png"

import rowsevenhero from "../../images/digital-strategy-row-seven-hero.png"

import roweighthero from "../../images/row-eight-hero.jpg"
import rowninehero from "../../images/row-nine-hero.jpg"
import rowtenhero from "../../images/row-ten-hero.png"

import "../../scss/capabilitypage.scss"

const DigitalStrategy = ({ data }) => {
  const metadescription =
    "Next&Co's  digital strategy services align your brands activity with measurable value, visit our site or call 1300 191 950"
  const metatitle = "Digital Strategy Agency Melbourne - Next&Co"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow />
      <div className="capability-page-general digital-strategy-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Digital Strategy"
            description="Digital Strategy – Aligning your brands digital activity with measurable value"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={marketanylsis} />
                        </span>
                        <div className="content">
                          <h3>Market analysis</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={gotomarketstrategy} />
                        </span>
                        <div className="content">
                          <h3>
                            Go to market <br /> strategy
                          </h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={digitalaudits} />
                        </span>
                        <div className="content">
                          <h3>Digital audits</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={digitalactivityroadmaps} />
                        </span>
                        <div className="content">
                          <h3>
                            Digital activity <br /> roadmaps
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={mediastrategy}
                            style={{ maxWidth: `30px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Media strategy</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={contentandsocialstrategy} />
                        </span>
                        <div className="content">
                          <h3>
                            Content &amp; social <br /> strategy
                          </h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={uxcxstrategy} />
                        </span>
                        <div className="content">
                          <h3>UX/CX strategy</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={crmandloyaltystrategy} />
                        </span>
                        <div className="content">
                          <h3>
                            CRM &amp; loyalty <br /> strategy
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your digital strategy."
            linktitle1="Get in touch"
            link1="#"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="row">
                  <div className="col">
                    <img alt="" src={rowtwoheroleft} />
                  </div>
                  <div className="col">
                    <img alt="" src={rowtwoheroright} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-five">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Our strategy framework</h4>
                  <p>
                    Our strategic framework aims to develop a holistic,
                    evidence-based understanding of your brands current state
                    performance in the digital world and create a measurable,
                    commercially minded roadmap of digital activity to help
                    achieve stated targets.
                  </p>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowfivehero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Our digital activity</h4>
                  <p>
                    Our digital roadmaps split activity into three distinct
                    buckets.
                  </p>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowsixhero1} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero2} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-seven">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col">
                    <h4>Measure performance &amp; optimise</h4>
                    <p>
                      We believe in the power of consistent iteration and a
                      relentless pursuit of continuous improvement. All activity
                      is held accountable for performance against mutually
                      agreed upon metrics of success. <br /> It is our
                      consistent aim to optimise all our efforts and activity
                      towards achieving these targets and make your brands
                      digital activity perform better day by day.
                    </p>
                    <img alt="" src={rowsevenhero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>
                      Many of Australia's leading businesses partner with us,
                      you should too.
                    </h3>
                    <SingleButton buttonText="Get in touch" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  )
}

export default DigitalStrategy

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
